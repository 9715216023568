<template>
  <!--begin::Reports Page-->
  <div class="card-stretch">
    <!--begin::plant changer-->
    <the-plants-selector page="reports" />
    <!--end::plant changer-->

    <div v-if="thePlant" class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 pt-5 d-flex justify-content-between">
        <div class="card-title">
          <div class="card-label">
            <div class="font-weight-bolder">{{ thePlant.NAME }}</div>
            <div class="font-size-sm text-primary mt-2">
              {{ thePlant.ADDRESS }}
            </div>
          </div>
        </div>
        <b-dropdown
          size="sm"
          variant="primary"
          toggle-class="custom-v-dropdown btn font-weight-bold py-3 px-6"
          right
          no-flip
          :text="
            interval == 'yearly'
              ? 'Yearly'
              : interval == 'quarterly'
              ? 'Quarterly'
              : 'Monthly'
          "
          v-b-tooltip.hover="'Change Interval'"
          style="height: fit-content"
          ref="dropdown"
        >
          <b-dropdown-form style="width: 150px">
            <b-form-radio-group
              label="Select Interval"
              v-model="interval"
              @change="changeAndRelayout"
              :options="intervalRadios"
            ></b-form-radio-group>
          </b-dropdown-form>
          <!-- <b-dropdown-divider></b-dropdown-divider> -->
        </b-dropdown>
        <!--end::dropdown for interval change-->
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body d-flex flex-column">
        <!--begin::Items-->
        <div class="flex-grow-1">
          <!--begin::reports-->
          <div
            v-if="
              reportsReady &&
              plotList.length > 0 &&
              plotList[0].title == 'Weekly Battery Charge'
            "
            class="font-weight-bolder mb-5 font-size-h4"
          >
            Weekly Charts
          </div>
          <div v-if="reportsReady" class="row">
            <div
              v-for="plot in plotList.filter(
                (p) =>
                  p.title == 'Weekly Battery Discharge' ||
                  p.title == 'Weekly Battery Charge'
              )"
              :key="plot.title"
              class="col-12"
            >
              <!--begin::chart header-->
              <div class="text-primary font-weight-bold font-size-sm">
                {{ plot.title }} (UTC Time)
              </div>
              <div class="font-size-sm text-muted">
                {{ plot.description }}
              </div>
              <!--begin::chart header-->
              <!--begin::chart-->
              <div
                :id="plot.title.replace(/\s/g, '')"
                :ref="plot.title.replace(/\s/g, '')"
              ></div>
              <!--begin::chart-->
            </div>
          </div>
          <!--begin::notify user that reports are loading-->
          <div class="font-weight-bolder mb-5 font-size-h4">Report Charts</div>
          <div v-if="reportsReady" class="row">
            <div
              v-for="plot in plotList.filter(
                (p) =>
                  p.title != 'Weekly Battery Discharge' &&
                  p.title != 'Weekly Battery Charge'
              )"
              :key="plot.title"
              class="col-lg-6 col-xl-4"
            >
              <!--begin::chart header-->
              <div class="text-primary font-weight-bold font-size-sm">
                {{ plot.title }}
              </div>
              <div class="font-size-sm text-muted">
                {{ plot.description }}
              </div>
              <!--begin::chart header-->
              <!--begin::chart-->
              <div
                :id="plot.title.replace(/\s/g, '')"
                :ref="plot.title.replace(/\s/g, '')"
              ></div>
              <!--begin::chart-->
            </div>
          </div>
          <!--begin::notify user that reports are loading-->
          <div v-else class="row">
            <div
              class="col-12 d-flex align-items-center flex-column flex-md-row justify-center mb-8"
            >
              <div
                class="spinner-grow text-primary mr-4"
                style="width: 3rem; height: 3rem"
              ></div>
              <span class="font-size-h2"
                >Please wait we are generating report charts for you...</span
              >
            </div>
          </div>
          <!--end::notify user that reports are loading-->
          <!--end::reports-->
          <!--begin::download-->
          <div class="font-weight-bolder mb-5 font-size-h4">Download</div>
          <div class="mb-10">
            <!--begin::form-->
            <form @submit.prevent="reportExport">
              <div class="form-row">
                <!--begin::date picker 1-->
                <div class="form-group col-md-3">
                  <b-form-datepicker
                    id="startDate"
                    v-model="startDate"
                    class="mb-2"
                    placeholder="Start Date"
                  ></b-form-datepicker>
                </div>
                <!--end::date picker 1-->
                <!--begin::date picker 2-->
                <div class="form-group col-md-3">
                  <b-form-datepicker
                    id="endDate"
                    v-model="endDate"
                    class="mb-2"
                    placeholder="End Date"
                  ></b-form-datepicker>
                </div>
                <!--end::date picker 1-->
              </div>
              <div class="form-row">
                <!--begin::dropdown select-->
                <div class="form-group col-md-3">
                  <b-form-select
                    v-model="reportType"
                    :options="reportTypes"
                  ></b-form-select>
                </div>
                <!--end::dropdown select-->
              </div>
              <button
                :disabled="downloadState"
                type="submit"
                v-bind:class="{
                  'spinner spinner-white spinner-right': downloadState,
                }"
                class="btn btn-primary"
              >
                Download Report
              </button>
              <small id="downloadHelp" class="form-text text-muted"
                >Download Data in .CSV Format</small
              >
            </form>
            <!--end::form-->
          </div>
          <!--end::download-->
        </div>
        <!--end::Items-->
      </div>
      <!--end::Body-->
    </div>
  </div>
  <!--end::Reports Page-->
</template>

<script>
import Plotly from "plotly.js-dist";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ThePlantsSelector from "../../../components/ThePlantsSelector.vue";
import { mapGetters } from "vuex";
import store from "@/core/services/store";
import { CHANGE_ACTIVE_SITE } from "@/store/ems.store";
import { Auth } from "aws-amplify";
import Swal from "sweetalert2";

export default {
  name: "reports",
  components: {
    ThePlantsSelector,
  },
  data() {
    return {
      user: {},
      token: "",
      thePlant: null,
      plotList: [],
      // setting plotly charts behavior
      plotlyLayout: {
        xaxis: {
          fixedrange: true,
          autorange: true,
          automargin: true,
        },
        yaxis: {
          autorange: true,
          fixedrange: true,
          automargin: true,
          hoverformat: ".0f",
        },
        margin: {
          l: 5,
          r: 30,
          t: 15,
        },
      },
      plotlyLayoutSpecial: {
        yaxis: {
          autorange: true,
          fixedrange: true,
          automargin: true,
          hoverformat: ".0f",
        },
        margin: {
          l: 5,
          r: 30,
          t: 15,
        },
      },
      reportType: null,
      reportsReady: false,
      startDate: "",
      endDate: "",
      reportTypes: [
        { value: null, text: "Report Type" },
        { value: "PV Production", text: "PV Production" },
        { value: "Battery Charge", text: "Battery Charge" },
        { value: "Battery Discharge", text: "Battery Discharge" },
        { value: "Net Facility Load", text: "Net Facility Load" },
        { value: "Net Energy Metered", text: "Net Energy Metered" },
      ],
      downloadState: false,
      interval: "monthly", // default interval set to Monthly
      intervalRadios: [
        { text: "Monthly", value: "monthly" },
        { text: "Quarterly", value: "quarterly" },
        { text: "Yearly", value: "yearly" },
      ],
      graphData: null,
    };
  },
  computed: {
    // ...mapGetters(["layoutConfig"])
    ...mapGetters(["getEmsList", "getEmsById"]),
  },
  async mounted() {
    try {
      this.user = await Auth.currentAuthenticatedUser();
      this.token = this.user.signInUserSession.idToken.jwtToken;
    } catch (error) {
      console.log(error);
      this.$router.push({ name: "login" });
      return;
    }
    if (this.getEmsList.length == 0) {
      this.$router.push(`/dashboard`);
    }

    if (this.getEmsList) {
      this.thePlant = this.getEmsList.find(
        (ems) => ems.SITE_ID == this.$route.params.siteName
      );
      this.getGraphData();
    }

    store.dispatch(CHANGE_ACTIVE_SITE, this.$route.params.siteName);

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: this.thePlant.NAME },
      { title: "Reports" },
    ]);
  },
  beforeRouteUpdate(to, from, next) {
    this.reportsReady = false;
    if (this.getEmsList) {
      this.thePlant = this.getEmsList.find(
        (ems) => ems.SITE_ID == to.params.siteName
      );
      this.getGraphData();
    }

    store.dispatch(CHANGE_ACTIVE_SITE, to.params.siteName);

    const myEms = this.getEmsById(to.params.siteName);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: myEms ? myEms.NAME : to.params.siteName },
      { title: "Reports" },
    ]);

    next();
  },
  methods: {
    plotsMounted() {
      for (let i = 0; i < this.plotList.length; i++) {
        let refStr = this.plotList[i].title.replace(/\s/g, "");
        let el = document.getElementById(refStr);
        if (
          this.plotList[i].title == "Weekly Battery Discharge" ||
          this.plotList[i].title == "Weekly Battery Charge"
        ) {
          Plotly.newPlot(el, this.plotList[i].data, this.plotlyLayoutSpecial, {
            displayModeBar: false,
            responsive: true,
          });
        } else {
          Plotly.newPlot(el, this.plotList[i].data, this.plotlyLayout, {
            displayModeBar: false,
            responsive: true,
          });
        }
      }
    },
    getGraphData() {
      //const intervalStart = new Date(
      //  this.thePlant.CONTRACT_START
      //).getTime();
      //const intervalEnd = new Date().getTime();

      ApiService.query("/api/etl/get_trace_by_range_etl", {
        params: {
          jwtToken: this.token,
          siteId: this.thePlant.SITE_ID,
          range: this.interval,
        },
      }).then((res) => {
        this.graphData = res.data;
        this.reportsReady = true;
        this.changeInterval(this.interval);
        this.$nextTick(() => {
          this.plotsMounted();
        });
      });
    },
    getXLong(arr) {
      if (arr.length > 0) {
        let x = [];
        arr.map((item) => {
          let d = new Date(item.timeMutc);
          x.push(d.toISOString());
        });
        return x;
      } else {
        return [0];
      }
    },
    getX(arr) {
      if (arr.length > 0) {
        let x = [];
        arr.map((item) => {
          let d = new Date(item.timeMutc);
          x.push(d.toISOString().split("T")[0]);
        });
        return x;
      } else {
        return [0];
      }
    },
    getXAxisFormat(intervalSize) {
      let format = {
        tickmode: "linear",
        fixedrange: true,
        automargin: true,
      };
      let d = new Date();
      let range = [d, new Date()];
      if (intervalSize == "yearly") {
        //Lets do 5 years of yearly history
        d.setDate(1);
        d.setMonth(0);
        d.setFullYear(d.getFullYear() - 5);
        format.tick0 = d.toISOString().split("T")[0];
        format.dtick = "M12";
        range[0] = d;
      } else if (intervalSize == "quarterly") {
        // 2 years of quarterly data?
        d.setDate(1);
        d.setMonth(0);
        d.setFullYear(d.getFullYear() - 2);
        let tickvals = [];
        let ticktext = [
          "Q1 " + d.getFullYear().toString(),
          "Q2",
          "Q3",
          "Q4",
          "Q1 " + (d.getFullYear() + 1).toString(),
          "Q2",
          "Q3",
          "Q4",
          "Q1 " + (d.getFullYear() + 2).toString(),
          "Q2",
          "Q3",
          "Q4",
        ];

        for (let i = 0; i < 12; i++) {
          let today = new Date();
          today.setFullYear(today.getFullYear() - 2);
          today.setDate(1);
          today.setMonth(3 * i);
          tickvals.push(today);
        }
        format.tickmode = "array";
        format.tickvals = tickvals;
        format.ticktext = ticktext;
        //format.tick0 = d.toISOString().split('T')[0];
        //format.dtick = "M3";
        range[0] = d;
      } else if (intervalSize == "monthly") {
        // 1 years of monthly
        d.setDate(1);
        d.setFullYear(d.getFullYear() - 1);
        format.tick0 = d.toISOString().split("T")[0];
        format.dtick = "M1";
        format.tickformat = "%b %y";
        range[0] = d;
      }
      format.range = range;
      return format;
    },
    getY(arr) {
      if (arr.length > 0) {
        let y = [];

        arr.map((item) => {
          y.push(item.value);
        });

        return y;
      } else {
        return [0];
      }
    },
    getQuarterDate(date) {
      return `Q${Math.floor(date.getMonth() / 3 + 1)} ${date.getFullYear()}`;
    },
    reportExport() {
      if (this.reportType) {
        if (
          this.startDate == null ||
          this.startDate == "" ||
          this.endDate == null ||
          this.endDate == ""
        ) {
          Swal.fire({
            title: "",
            text: "Please select a valid start and end date\n",
            icon: "error",
            heightAuto: false,
          });
          return;
        }
        this.downloadState = true;
        let d = new Date(this.startDate);
        const intervalStart = d.getTime();

        //Need to convert to utc before changing time
        let d2 = new Date(this.endDate);
        d2 = new Date(d2.getTime() + d2.getTimezoneOffset() * 60000);
        d2.setHours(23, 59, 59, 999);
        const intervalEnd = d2.getTime() - d2.getTimezoneOffset() * 60000;

        if (intervalStart >= intervalEnd) {
          Swal.fire({
            title: "",
            text: "Start date must come before end date\n",
            icon: "error",
            heightAuto: false,
          });
          this.downloadState = false;
          return;
        }
        if (this.reportType == null || this.reportType == "Report Type") {
          Swal.fire({
            title: "",
            text: "Please select a valid report type\n",
            icon: "error",
            heightAuto: false,
          });
          this.downloadState = false;
          return;
        }

        ApiService.query("/api/etl/get_site_report_csv_etl", {
          params: {
            jwtToken: this.token,
            siteId: this.thePlant.SITE_ID,
            intervalStart: intervalStart,
            intervalEnd: intervalEnd,
            data: this.reportType,
          },
        }).then((res) => {
          if (res.status == 200) {
            var blob = new Blob([res.data]);
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `${this.reportType}-Report.csv`;
            link.click();
          }
          this.downloadState = false;
        });
      } else {
        Swal.fire({
          title: "",
          text: "Please select a valid report type\n",
          icon: "error",
          heightAuto: false,
        });
        return;
      }
    },
    changeAndRelayout() {
      this.reportsReady = false;
      this.getGraphData();
    },
    changeInterval(intervalSize) {
      this.plotList = [];
      this.plotlyLayout.xaxis = this.getXAxisFormat(intervalSize);

      for (let item in this.graphData) {
        if (item) {
          if (
            item == "Weekly Battery Discharge" ||
            item == "Weekly Battery Charge"
          ) {
            this.plotList.push({
              title: item,
              description: this.graphData[item].description,
              data: [
                {
                  x: this.getXLong(this.graphData[item].values),
                  y: this.getY(this.graphData[item].values),
                  type: "scatter",
                  mode: "lines",
                  hoverinfo: "y",
                },
              ],
            });
          } else {
            this.plotList.push({
              title: item,
              description: this.graphData[item].description,
              data: [
                {
                  x: this.getX(this.graphData[item].values),
                  y: this.getY(this.graphData[item].values),
                  type: "scatter",
                  mode: "lines",
                  hoverinfo: "y",
                },
              ],
            });
          }
        }
      }
      this.$nextTick(() => {
        for (let i = 0; i < this.plotList.length; i++) {
          let refStr = this.plotList[i].title.replace(/\s/g, "");
          let el = document.getElementById(refStr);
          if (
            this.plotList[i].title == "Weekly Battery Discharge" ||
            this.plotList[i].title == "Weekly Battery Charge"
          ) {
            Plotly.relayout(el, this.plotlyLayoutSpecial);
          } else {
            // our el does not have a ._fullLayout which causes trouble in here.
            // happens in prod version as well.
            Plotly.relayout(el, this.plotlyLayout);
          }
        }
      });
    },
  },
};
</script>
