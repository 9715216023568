var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-stretch"},[_c('the-plants-selector',{attrs:{"page":"reports"}}),(_vm.thePlant)?_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header border-0 pt-5 d-flex justify-content-between"},[_c('div',{staticClass:"card-title"},[_c('div',{staticClass:"card-label"},[_c('div',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.thePlant.NAME))]),_c('div',{staticClass:"font-size-sm text-primary mt-2"},[_vm._v(" "+_vm._s(_vm.thePlant.ADDRESS)+" ")])])]),_c('b-dropdown',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Change Interval'),expression:"'Change Interval'",modifiers:{"hover":true}}],ref:"dropdown",staticStyle:{"height":"fit-content"},attrs:{"size":"sm","variant":"primary","toggle-class":"custom-v-dropdown btn font-weight-bold py-3 px-6","right":"","no-flip":"","text":_vm.interval == 'yearly'
            ? 'Yearly'
            : _vm.interval == 'quarterly'
            ? 'Quarterly'
            : 'Monthly'}},[_c('b-dropdown-form',{staticStyle:{"width":"150px"}},[_c('b-form-radio-group',{attrs:{"label":"Select Interval","options":_vm.intervalRadios},on:{"change":_vm.changeAndRelayout},model:{value:(_vm.interval),callback:function ($$v) {_vm.interval=$$v},expression:"interval"}})],1)],1)],1),_c('div',{staticClass:"card-body d-flex flex-column"},[_c('div',{staticClass:"flex-grow-1"},[(
            _vm.reportsReady &&
            _vm.plotList.length > 0 &&
            _vm.plotList[0].title == 'Weekly Battery Charge'
          )?_c('div',{staticClass:"font-weight-bolder mb-5 font-size-h4"},[_vm._v(" Weekly Charts ")]):_vm._e(),(_vm.reportsReady)?_c('div',{staticClass:"row"},_vm._l((_vm.plotList.filter(
              (p) =>
                p.title == 'Weekly Battery Discharge' ||
                p.title == 'Weekly Battery Charge'
            )),function(plot){return _c('div',{key:plot.title,staticClass:"col-12"},[_c('div',{staticClass:"text-primary font-weight-bold font-size-sm"},[_vm._v(" "+_vm._s(plot.title)+" (UTC Time) ")]),_c('div',{staticClass:"font-size-sm text-muted"},[_vm._v(" "+_vm._s(plot.description)+" ")]),_c('div',{ref:plot.title.replace(/\s/g, ''),refInFor:true,attrs:{"id":plot.title.replace(/\s/g, '')}})])}),0):_vm._e(),_c('div',{staticClass:"font-weight-bolder mb-5 font-size-h4"},[_vm._v("Report Charts")]),(_vm.reportsReady)?_c('div',{staticClass:"row"},_vm._l((_vm.plotList.filter(
              (p) =>
                p.title != 'Weekly Battery Discharge' &&
                p.title != 'Weekly Battery Charge'
            )),function(plot){return _c('div',{key:plot.title,staticClass:"col-lg-6 col-xl-4"},[_c('div',{staticClass:"text-primary font-weight-bold font-size-sm"},[_vm._v(" "+_vm._s(plot.title)+" ")]),_c('div',{staticClass:"font-size-sm text-muted"},[_vm._v(" "+_vm._s(plot.description)+" ")]),_c('div',{ref:plot.title.replace(/\s/g, ''),refInFor:true,attrs:{"id":plot.title.replace(/\s/g, '')}})])}),0):_c('div',{staticClass:"row"},[_vm._m(0)]),_c('div',{staticClass:"font-weight-bolder mb-5 font-size-h4"},[_vm._v("Download")]),_c('div',{staticClass:"mb-10"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.reportExport.apply(null, arguments)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"id":"startDate","placeholder":"Start Date"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"id":"endDate","placeholder":"End Date"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('b-form-select',{attrs:{"options":_vm.reportTypes},model:{value:(_vm.reportType),callback:function ($$v) {_vm.reportType=$$v},expression:"reportType"}})],1)]),_c('button',{staticClass:"btn btn-primary",class:{
                'spinner spinner-white spinner-right': _vm.downloadState,
              },attrs:{"disabled":_vm.downloadState,"type":"submit"}},[_vm._v(" Download Report ")]),_c('small',{staticClass:"form-text text-muted",attrs:{"id":"downloadHelp"}},[_vm._v("Download Data in .CSV Format")])])])])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 d-flex align-items-center flex-column flex-md-row justify-center mb-8"},[_c('div',{staticClass:"spinner-grow text-primary mr-4",staticStyle:{"width":"3rem","height":"3rem"}}),_c('span',{staticClass:"font-size-h2"},[_vm._v("Please wait we are generating report charts for you...")])])
}]

export { render, staticRenderFns }